import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Head from "../components/Head"

const AboutPage = () => {
  return (
    <Layout>
      <div
        style={{
          background: "white",
          padding: "2rem 1rem",
          minHeight: "75vh",
          textAlign: "center"
          // marginTop: "10%"
        }}
      >
        <Head title="About" />
        <h1 style={{ marginTop: "10%" }}>About Ethio telecom</h1>
        <p>Telecom Service Provider in Ethiopia</p>
        <p>
          <Link to="https://www.ethiotelecom.et/profile/" target="_blank">
            Learn More About Ethio telecom.
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default AboutPage
